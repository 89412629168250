import React, { useState, useEffect, useCallback, useRef } from "react"

import { Card, ListGroup, Dropdown, ButtonGroup, Button, Modal, Form } from "react-bootstrap"
import axios from "axios";
import { Marker } from "react-map-gl";

import PropTypes from "prop-types"
import { Element } from "react-scroll"

import MapView from "../Map/Map";
import ForestDetailsHistogram from "./ForestDetailsHistrogram";
import ForestDetailsSummary from "./ForestDetailsSummary";
import ForestDetailsIndividualTrees from "./ForestDetailsIndividualTrees";
import DeleteDialogPopup from "../DialogPopup/DeleteDialogPopup";
import DownloadDialogPopup from "../DialogPopup/DownloadDialogPopup";
import DownloadGeojsonPopup from "../DialogPopup/DownloadGeojsonPopup";
import DownloadFlightPathDialogPopup from "../DialogPopup/DownloadFlightPathDialogPopup ";
import { useMediaQuery } from "../../helpers/mediaQuery";
import { listgroupForestDetails, testForestData } from "../../helpers/testdata";
import { CSVLink } from "react-csv";
import { FaFilter } from 'react-icons/fa'

import Pin from "../Map/pin";
import useCoord from "../App/useCoord";
import useToken from "../App/useToken";
import { customerDbhSettings, customerVolumeSettings, customerHeightSettings } from "../../helpers/customerSettings";
import Helper, { Access, hasAccess } from "../Helper/Helper";
import EditDialogPopup from "../DialogPopup/EditTableDialogPopup";
import FlightReport from "../DashboardTab/Report/flightReport";
import DownloadGeoDialogPopup from "../DialogPopup/DownloadGeoDialogPopup ";
import DownloadCSVPopup from "../DialogPopup/DownloadCSVPopup";


var testEnv = process.env.REACT_APP_TEST_ENV
const reduceTreedata = (treeData, area) => {
    // console.log(treeData)
    const firstRowFull = {
        "Alias": '',
        "ID": "",
        "x": "", 
        "y": "", 
        "DBH": "", 
        "volume": "", 
        "height": "", 
        "lon": "",
        "lat": "",
        "elev": "",
        "heightRaw": "", //# Estimated as the difference between smallest and largest height
        "type": "",
        "method": ""}
    const data = treeData.reduce(
        (acc, { properties  }) => ({                       
            DBH: 0 < properties.DBH && properties.DBH < 0.8 ? [...acc.DBH, ["DBH", Math.round(Number(properties.DBH.toFixed(3)*100))*0.01]] : [...acc.DBH],
            volume: 0 < properties.volume && properties.volume < 5 && 0 < properties.DBH && properties.DBH < 0.8 ? [...acc.volume,["Volume", Math.round(Number(properties.volume.toFixed(3))*100)*0.01]] : [...acc.volume],
            height: 0 < properties.height && properties.height< 30 && 0 < properties.DBH && properties.DBH < 0.8 ? [...acc.height, ["Height", Math.round(Number(properties.height.toFixed(3))*100)*0.01]] : [...acc.height],
            sumDBH: 0 < properties.DBH && properties.DBH < 0.8 ? acc.sumDBH + properties.DBH : acc.sumDBH + 0,
            basalArea: 0 < properties.DBH && properties.DBH < 0.8 ? acc.basalArea + ((Number(properties.DBH)/2)*(Number(properties.DBH)/2))*Math.PI : acc.basalArea + 0,
            sumVol:  0 < properties.volume && properties.volume < 5 && 0 < properties.DBH && properties.DBH < 0.8 ? acc.sumVol + properties.volume : acc.sumVol + 0,
            sumHeight: 0 < properties.height && properties.height< 30 && 0 < properties.DBH && properties.DBH < 0.8 ? acc.sumHeight + properties.height : acc.sumHeight + 0,
            discardedTrees: properties.DBH >= 0.8 ? acc.discardedTrees + 1 : acc.discardedTrees + 0,
            possibleTrees: properties.DBH > 0 && properties.volume !== -1 ? [...acc.possibleTrees, {"ID": properties.ID,"lon": properties.lon, "lat": properties.lat, "DBH": Number(properties.DBH.toFixed(3)), "volume": Number(properties.volume.toFixed(3)), "height": Number(properties.height.toFixed(3))} ] : [...acc.possibleTrees],
            Full: [...acc.Full, {
                "Alias": "",
                "ID": properties.ID,
                "x": properties.x, 
                "y": properties.y, 
                "DBH": Number(properties.DBH.toFixed(3)), 
                "volume": Number(properties.volume.toFixed(3)), 
                "height": Number(properties.height.toFixed(3)), 
                "lon": properties.lon ? properties.lon : 0.00,
                "lat": properties.lat ? properties.lat : 0.00,
                "elev": properties.elev ? properties.elev : 0,
                "heightRaw": properties.heightRaw ? properties.heightRaw : 0, //# Estimated as the difference between smallest and largest height
                "type": properties.type ? properties.type : "",
                "method": properties.method ? properties.method : ""} ]
        }),
        { Full: [firstRowFull], possibleTrees: [], DBH: [["DBH", "0"]], volume: [["Volume", "0"]], height: [["Height", "0"]], sumDBH: 0, sumVol: 0, sumHeight: 0, area: area, discardedTrees:0, basalArea: 0}
    )
    return data
}
export default function ForestDetails(props) {
    const isRowBased = useMediaQuery("(max-width: 1050px)");
    const isAllRowBased = useMediaQuery("(max-width: 570px)");
    const [shapeData, setShapeData] = useState([])
    const [bagData, setBagData] = useState(testEnv === "false" ? [] : listgroupForestDetails)
    const {token, getCustomer} = useToken()
    const [customer, setCustomer] = useState(getCustomer())

    const {coord} = useCoord()
    const defaultCoord = useRef(coord) 
  
    const setNewViewport = async (coordinates) => {
      let zoom = props.forestDetailMapRef.current.getZoom()
    
      props.forestDetailMapRef.current.easeTo({
        center: coordinates,
        zoom: zoom < 15 ? 15 : zoom,
        duration: 2000
      });
    }

    // const onSelectBagfile = (selectedBagfile) => {
    //     var longitude = defaultCoord.current.longitude
    //     var latitude = defaultCoord.current.latitude
    //     if(!Array.isArray(selectedBagfile.longitude)){
    //         longitude = -180.0 < selectedBagfile.longitude && selectedBagfile.longitude < 180.0 ? selectedBagfile.longitude : longitude
    //         latitude =  -90.0 < selectedBagfile.latitude && selectedBagfile.latitude < 90.0 ? selectedBagfile.latitude : latitude 
    //     }
    //     setNewViewport([longitude, latitude])
    // }
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
            <a
                href=""
                ref={ref}
                onClick={e => {
                e.preventDefault();
                onClick(e);
                }}
            >
                <FaFilter style={{width: "1.5em",  height: "1.5em", color: "#a3de28"}}/>
                {children}
            </a>
        ))
    useEffect(() => {
        var longitude = defaultCoord.current.longitude  
        var latitude = defaultCoord.current.latitude
        if(props.ctx.showShapeData?.current){
          if(props.ctx.selectedShapeData?.current._id === "") {
            return
          }
          const feature = props.ctx.selectedShapeData?.current.geojson.features[0]
          const type = feature.geometry.type
          if(String(type).toLowerCase().includes('polygon')){
            if(String(type).toLowerCase() === 'multipolygon'){
              longitude = feature.geometry.coordinates[0][0][0][0]
              latitude = feature.geometry.coordinates[0][0][0][1]
            } else {
              longitude = feature.geometry.coordinates[0][0][0]
              latitude = feature.geometry.coordinates[0][0][1]
            }
          } else {
            longitude = feature.geometry.coordinates[0]
            latitude = feature.geometry.coordinates[1]
          }    
          setNewViewport([longitude, latitude]) 
        } else {
          if(props.selectedBagfile._id === "") {
            return
          }
          if(!Array.isArray(props.selectedBagfile.longitude)){
          // console.log({longitude: props.selectedBagfile.longitude, latitude: props.selectedBagfile.latitude})
            longitude = -180.0 < props.selectedBagfile.longitude && props.selectedBagfile.longitude < 180.0 ? props.selectedBagfile.longitude : longitude
            latitude =  -90.0 < props.selectedBagfile.latitude && props.selectedBagfile.latitude < 90.0 ? props.selectedBagfile.latitude : latitude
          }
          setNewViewport([longitude, latitude])
        }
        // console.log(props.selectedBagfile)
        //console.log({longitude: longitude, latitude: latitude})
        
      }, [props.selectedBagfile, props.ctx?.selectedShapeData?.current])
    const handleClick = ( item) => {
        if(props.ctx.showShapeData?.current){
            props.ctx.selectShapeData(item)
        } else {
        // console.log(props.selectedBagfile)
        props.setSelectedBagfile(item) 
        }
        // onSelectBagfile(item)
    }

    const removeSelectedTrees = (trees) => {
        var formData = new FormData();
        formData.append("token", token);
        formData.append("id", props.selectedBagfile._id);
        formData.append("customerId", props.selectedBagfile.customerId);
        formData.append("name", props.selectedBagfile.name);
        formData.append("ids", trees);
        
        var config = {
            method: "post",
            url: "file-handler/remove_measurements",
            data: formData
          };
          axios(config).then(response => {
            // console.log(response.data)
        }).catch(error => {
            console.log('error', error)
        })

    }
    const [selectedItemsToRemove, setSelectedItemsToRemove] = useState([])
    const updateSelectedItemsToRemove = (id) => {
        let index = selectedItemsToRemove.findIndex(i => i === id )
        let temp = [...selectedItemsToRemove]
        if(index > -1){
            temp.splice(index, 1)
        } else {
            temp.push(id) 
        }
        setSelectedItemsToRemove(temp)
    }
    const selectedItemsToRemoveRef = useRef()
    selectedItemsToRemoveRef.current = selectedItemsToRemove
    const [editTable, setEditTable] = useState(false)
    const toggleEditTable = () => {
        setEditTable(!editTable)
    }
    const editTableRef = useRef()
    editTableRef.current = editTable
    const [viewTable, setViewTable] = useState()
    const toggleViewTable = () => {
        setViewTable(!viewTable)
    }
    const [viewFlightReport, setViewFlightReport] = useState(false)
    const toggleViewFlightReport = () => {
        setViewFlightReport(!viewFlightReport)
    }

    const [exportData, setExportData] = useState({Full: [], name: ""})
    const [exportItem, setExportItem] = useState(null)
    const [showExportCsv, setShowExportCsv] = useState(false)

    const getExportData = async (item) => {
        var formData = new FormData();
        formData.append("token", token);
        formData.append("id", item._id)
        
        var config = {
            method: "post",
            url: "api/forestdata",
            data: formData
          };
          axios(config)
          .then(response => 
           {    
                // console.log(response.data)
                const fetchedData = response.data
                console.log(fetchedData)
                if(fetchedData.forestdata && fetchedData.forestdata.treeData){
                    var tmp = reduceTreedata(fetchedData.forestdata.treeData.features, fetchedData.forestdata.area)
                    // console.log(tmp)                  
                    setExportData({Full: tmp.Full, name: item.alias ? item.alias : item.name})
                    // console.log(data)
                    setShowExportCsv(true)
                }
                else if(fetchedData.TreeData) {
                    const data = fetchedData.TreeData.reduce(
                        (acc, { DBH, volume, height, ID, x, y }) => ({
                            Full: [...acc.Full, {"ID": ID,"x": x, "y": y, "DBH": Number(DBH.toFixed(3)), "volume": Number(volume.toFixed(3)), "height": Number(height.toFixed(3))} ]
                        }),
                        { Full: []}
                    )
                    setExportData({Full: data.Full, name: item.alias ? item.alias : item.name})
                    // console.log(data)
                    setShowExportCsv(true)
                }
          })
          .catch(error => {
            console.log("error", error)
          })
        setShowExportCsv(true)
    }

    useEffect(() => {
        if(exportItem !== null){
            getExportData(exportItem)
        }
    }, [exportItem]) 

    const handleClose = () => {
        setShowExportCsv(false)
        setExportItem(null)
        setExportData({Full: [], name: ""})
    
    }

    const headers = [
        
        { label: "ID", key: "ID" },
        { label: "DBH", key: "DBH" },
        { label: "Volume", key: "volume" },
        { label: "Height", key: "height" },
        { label: "X", key: "x" },
        { label: "Y", key: "y" },
    ]
    const exportModal = exportData.name !== "" ? (
        <Modal
            show={showExportCsv}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{exportData.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Do you want to export to CSV?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose()}>
                    Cancel
                </Button>
                <CSVLink data={exportData.Full} headers={headers} filename={exportData.name}>
                    <Button variant="primary">Export</Button>
                </CSVLink>
            </Modal.Footer>
        </Modal>
    ) : null

    const [downloadDialogShow, setDownloadDialogShow] = useState(false)
    const [downloadGeoDialogShow, setDownloadGeoDialogShow] = useState(false)
    const [downloadFlightPathDialogShow, setDownloadFlightPathDialogShow] = useState(false)
    const [downloadGeojsonDialogShow, setDownloadGeojsonDialogShow] = useState(false)
    const [downloadCSVDialogShow, setDownloadCSVDialogShow] = useState(false)
    const [itemToDownload, setItemToDownload] = useState(null)
    const [deleteDialogShow, setDeleteDialogShow] = useState(false)
    const [editDialogShow, setEditDialogShow] = useState(false)
    const [itemToDelete, setItemToDelete] = useState(null)
    const handleEdit = () => {
        // console.log(selectedItemsToRemoveRef.current)
        setEditDialogShow(false)
        updateSelectedItemsToRemove([])
    }
    const handleDelete = (item) => {
        var formData = new FormData();
        var token = sessionStorage.getItem("token")
        formData.append("token", token);
        formData.append("name", item.name);
        formData.append("DOF", item.DOF);
        formData.append("droneId", item.droneId);
        formData.append("customerId", item.customerId);
        formData.append("_id", item._id);
        var config = {
            method: "post",
            url: "file-handler/delete_item",
            data: formData
        };
        axios(config).then(response => {
            // console.log(response)
            if(itemToDelete._id === props.selectedBagfile._id) {
                setforestData(null)
                props.setSelectedBagfile({_id: "", name:""})            
            }
            setDeleteDialogShow(false)
            setItemToDelete(null)
        }).catch(error => {
          console.log("error", error)
        })   
    }
    const setDemo = (item) => {
        const isDemo = !item?.isDemo
        const data = {
            "token": token,
            "isDemo": isDemo,
            "_id": item._id
        }
        
        var config = {
            method: "post",
            url: "api/update_dronefile",
            data: data
        };

        axios(config).then(response => {
            
        }).catch(error => {
          console.log("error", error)
        })
    }
    const setReviewed = (item) => {
        const isReviewed = !item?.isReviewed
        const data = {
            "token": token,
            "isReviewed": isReviewed,
            "_id": item._id
        }
        
        var config = {
            method: "post",
            url: "api/update_dronefile",
            data: data
        };

        axios(config).then(response => {
            
        }).catch(error => {
          console.log("error", error)
        })
    }

    const mergeStandData = (item) => {
        const formData = new FormData();
        formData.append('AID', item.AID);
        formData.append('token', token);
        
        var config = {
            method: "post",
            url: "file-handler/assembleStandData",
            data: formData
        };

        axios(config).then(response => {
            // console.log(response)
        }).catch(error => {
          console.log("error", error)
        })
    }
    const openDeleteDialog = (item) => {
        setItemToDelete(item)
        setDeleteDialogShow(true)
        
    }
    const openDownloadDialog = (item) => {
        setItemToDownload(item)
        setDownloadDialogShow(true)
        
    }
    const openDownloadGeoDialog = (item) => {
        setItemToDownload(item)
        setDownloadGeoDialogShow(true)
        
    }
    const openDownloadFlightPathDialog = (item) => {
        setItemToDownload(item)
        setDownloadFlightPathDialogShow(true)   
    }
    const openDownloadGeojsonDialog = () => {
        if(props.ctx?.showShapeData?.current && props.ctx?.selectedShapeData?.current.AID){
            console.log('opening stand geojson download')
            setItemToDownload(props.ctx?.selectedShapeData?.current)
            // console.log(props.ctx?.selectedShapeData?.current)
            setDownloadGeojsonDialogShow(true)   
        } else if(props.selectedBagfile?._id){
            setItemToDownload(props.selectedBagfile)
            setDownloadGeojsonDialogShow(true)   
        }
    }
    const openDownloadCSVDialog = () => {
        if(props.ctx?.showShapeData?.current && props.ctx?.selectedShapeData?.current.AID){
            console.log('opening stand CSV download')
            setItemToDownload(props.ctx?.selectedShapeData?.current)
            // console.log(props.ctx?.selectedShapeData?.current)
            setDownloadCSVDialogShow(true)   
        } else if(props.selectedBagfile?._id){
            setItemToDownload(props.selectedBagfile)
            setDownloadCSVDialogShow(true)   
        }
    }
    const [standFlights, setStandFlights] = useState([]) 
    // const [geoData, setGeoData] = useState()
    const [pins, setPins] = useState([])
    const [geoData, setGeoData] = useState(null)
    
    const [standData, setStandData] = useState(null)

    const getStandData = () => {
        const data = {
            'token': token,
            'AID': props.selectedShapeData.AID
        }
        var config = {
            method: "post",
            url: "api/get_stand_data",
            data: data
        };
        axios(config)
        .then(response => {
            const standData = response.data.standData
            if(standData){
                setStandData(standData)
            }
        })
        .catch(error => {
            setStandData(null)
            console.log(error)
        })
    }
    const [treeData, setTreeData] = useState(null)

    const getTreeData = () => {
        const data = {
            'token': token,
            'bagFileId': props.selectedBagfile._id
        }
        var config = {
            method: "post",
            url: "api/get_tree_data",
            data: data
        };
        axios(config)
        .then(response => {
            const treeData = response.data.treeData
            if(treeData){
                setTreeData(treeData)
            }
        })
        .catch(error => {
            setTreeData(null)
            console.log(error)
        })
    }
    useEffect(()=> {
        // const data = {
        //     'token': token,
        //     'customerId': props.filterCustomerData,
        //     'filterAID': props.ctx.filterAID
        // }
        // var config = {
        //     method: "post",
        //     url: "api/get_filtered_dronefiles",
        //     data: data
        // };
        // axios(config)
        // .then(response => 
        props.ctx.setForestdata({
            possibleTrees: 0,  
            area: 0, 
            basalArea: 0,
        })
        if(props.ctx.showShapeData?.current){
            //console.log(props.ctx.surveyArea?.current)
            if(props.ctx.surveyArea?.current){
                setShapeData(props.ctx.surveyArea?.current.map((item, index) => {
                    // const status = Helper.getStatus(item.processState)
                    // const proccessing = status !== '' ? ' - ' + status : ''
                    // // String(item.processState).includes('Failed') ? " - "+item.processState :
                    // //   item.processState !== "Finished" ? " - "+ item.processState : ""
                    // const hasAlias = item.alias && item.alias !== ""
                    // const name = hasAlias ?  item.alias : item.name
                    return (
                        <Dropdown style={{marginBottom: "1em", lineBreak: "anywhere" }} as={ButtonGroup}>
                            <ListGroup.Item
                            action
                            variant={props?.ctx.selectedShapeData?.current?._id === item._id ? "info" : item.mergedState === "merged" ? 'success' : item.mergedState === "newData" ? 'warning' : ""}
                            active={props?.ctx.selectedShapeData?.current?._id === item._id}
                            eventKey={item._id} 
                            key={item._id} 
                            onClick={(e) => {
                                handleClick(item) 
                                e.preventDefault()
                                e.stopPropagation()}}
                            >
                                {item.alias +" - "+ item.state}
                            </ListGroup.Item>
                            <Dropdown.Toggle split variant="outline-secondary" id="dropdown-button-drop"/>

                                <Dropdown.Menu variant="dark">
                                    {hasAccess(customer, Access.MERGE_STAND_DATA.number) && <Dropdown.Item  eventKey={`merge-data-${item._id}`} onClick={() => {mergeStandData(item)}}>
                                        Merge stand data
                                    </Dropdown.Item>}
                                    {/* {(customer.customerId === '101010' || customer.customerId === '000006' || customer.customerId === '000005' || customer.customerId === '000015' || customer.customerId === '000018') && <Dropdown.Item  eventKey={`download-${item._id}`} onClick={() => {openDownloadDialog(item)}}>
                                        Download pcds for stand...
                                    </Dropdown.Item>} */}
                                    {/* {customer.customerId === '101010' && <Dropdown.Item disabled={!(String(item.processState).includes('Failed') || item.processState.includes("Finished"))} eventKey={`download-${item._id}`} onClick={() => {openDownloadDialog(item)}}>
                                        Download pcd...
                                    </Dropdown.Item>}
                                    { customer.customerId === '101010' && <Dropdown.Item disabled={!(String(item.processState).includes("Failed") || item.processState.includes("Finished"))} eventKey={`download-${item._id}`} onClick={() => {setDemo(item)}}>
                                        {item.isDemo ? "Remove as demo" : "Set as demo"}
                                    </Dropdown.Item>}
                                    <Dropdown.Divider />
                                    {customer.customerId === '101010' && <Dropdown.Item disabled={!(String(item.processState).includes('Failed') || item.processState.includes("Finished"))} eventKey={`RetryProcessing-${item._id}`} onClick={() => {restartConvertion(item)}}>
                                        Restart processing
                                    </Dropdown.Item>}
                                    <Dropdown.Item eventKey={`ChangeAlias-${item._id}`} onClick={() => {openChangeAliasDialog(item)}}>
                                        Change alias...
                                    </Dropdown.Item>
                                    {customer.customerId === '101010' && <Dropdown.Item disabled={!(item.processState.includes("Finished") || String(item.processState).includes('Failed'))} eventKey={`Delete-${item._id}`} onClick={() => {openDeleteDialog(item)}}>
                                        Delete...
                                    </Dropdown.Item>} */}
                                </Dropdown.Menu>
                        </Dropdown>
                        )
                }))
            }
        } else {
            
            if(props.bagData){
                if((!props.fromDate || !props.toDate) && !props.filteredItems){
                    console.log("not filtered")
                    createDronefileList(props.sortItems(props.bagData, props.sortOrder));
                } else {
                    console.log('filtered')
                    createDronefileList(props.sortItems(props.filteredItems, props.sortOrder));
                }
            }
        }
    }, [props.bagData, props.selectedBagfile, props.ctx.showShapeData?.current, props.ctx.shapeData?.current, props.ctx.selectedShapeData?.current, props.ctx?.filterBagData, props.filteredItems])
    
    const createDronefileList = (fetchedData) => {
                if(fetchedData){
                let pindata = []
                let tmpStandFlights = []
                if(Array.isArray(fetchedData)) {
                    setGeoData(fetchedData)
                    setBagData(fetchedData.map( item => {
                        if(props.ctx?.filterBagData === 'notReviewed'){
                            if(item.isReviewed){
                                return
                            }
                        } else if(props.ctx?.filterBagData === 'reviewed'){
                            if(!item.isReviewed){
                                return
                            }
                        }
                        // if(props.ctx?.showShapeData?.current){
                        //     if(props.ctx?.selectedShapeData?.current.AID !== ""){
                        //         if(item.AID !== props.ctx?.selectedShapeData?.current.AID){    
                        //             return
                        //         } else {
                        //             tmpStandFlights.push(item)
                        //         }
                        //     } else {
                        //         return
                        //     }
                        // }
                        if(item.AID){
                            // console.log(item)
                        }
                        const status = Helper.getStatus(item.processState)
                        const proccessing = status !== '' ? ' - ' + status : ''
                        const hasAlias = item.alias && item.alias !== ""
                        const name = hasAlias ? item.alias : item.name
                        return (
                            <Dropdown style={{marginBottom: "1em", lineBreak: "anywhere" }} as={ButtonGroup}>
                                <ListGroup.Item
                                    action
                                    variant={props.selectedBagfile?._id === item._id ? "info" : ""}
                                    active={props.selectedBagfile?._id === item._id}
                                    eventKey={item._id} 
                                    key={item._id} 
                                    onClick={(e) => {
                                        handleClick(item) 
                                        e.preventDefault()
                                        e.stopPropagation()}}
                                    disabled={item.processState !== "Finished"}>
                                    {name + proccessing}
                                </ListGroup.Item>
                                <Dropdown.Toggle split variant="outline-secondary" id="dropdown-button-drop"/>

                                <Dropdown.Menu variant="dark">
                                    {/* <Dropdown.Item disabled={item.processState !== "Finished"} eventKey={`ExportCSV-${item._id}`} onClick={() => setExportItem(item)}>
                                        Export csv
                                    </Dropdown.Item> */}
                                    {hasAccess(customer, Access.DOWNLOAD_PCD.number) && <Dropdown.Item disabled={!(String(item.processState).includes("Failed") || item.processState.includes("Finished"))} eventKey={`download-${item._id}`} onClick={() => {openDownloadDialog(item)}}>
                                        Download pcd...
                                    </Dropdown.Item>}
                                    {hasAccess(customer) && <Dropdown.Item disabled={!(String(item.processState).includes("Failed") || item.processState.includes("Finished"))} eventKey={`download-geo-${item._id}`} onClick={() => {openDownloadGeoDialog(item)}}>
                                        Download georeferenced data...
                                    </Dropdown.Item>}
                                    {hasAccess(customer, Access.DOWNLOAD_FLIGHT_PATH.number) && <Dropdown.Item disabled={!(String(item.processState).includes("Failed") || item.processState.includes("Finished"))} eventKey={`download-${item._id}`} onClick={() => {openDownloadFlightPathDialog(item)}}>
                                        Download flight path...
                                    </Dropdown.Item>}
                                    { hasAccess(customer) && <Dropdown.Item  eventKey={`review-${item._id}`} onClick={() => {setReviewed(item)}}>
                                            {item.isReviewed ? "Remove as reviewed" : "Set as reviewed"}
                                    </Dropdown.Item>}
                                    {hasAccess(customer) && <Dropdown.Item disabled={!(String(item.processState).includes("Failed") || item.processState.includes("Finished"))} eventKey={`download-${item._id}`} onClick={() => {setDemo(item)}}>
                                        {item.isDemo ? "Remove as demo" : "Set as demo"}
                                    </Dropdown.Item>}
                                    <Dropdown.Divider />
                                    {hasAccess(customer) && <Dropdown.Item disabled={!(item.processState === "Finished" || String(item.processState).includes("Failed"))} eventKey={`Delete-${item._id}`} onClick={() => {openDeleteDialog(item)}}>
                                        Delete...
                                    </Dropdown.Item>}
                                </Dropdown.Menu>
                            </Dropdown>)
                    }))

                fetchedData.forEach((data, index)  => {
                    if(data.longitude){ 
                      pindata.push(
                        (<Marker
                          key={`marker-${index}`}
                          longitude={data.longitude}
                          latitude={data.latitude}
                          anchor="bottom"
                        >
                          <Pin size={20}/>
                        </Marker>))
                    }
                  })
                  setPins(pindata)
                // let geodata = []
                // fetchedData.forEach(data => {
                //     if(Array.isArray(data.latitude)){
                //         console.log(data)
                //         geodata.push({ 
                //             "type": "Feature",
                //             "properties": { "name": data.name, "latitude": data.latitude[0],"longitude":data.longitude[0]},
                //             "geometry": { 
                //                 "type": "Polygon",
                //                 "coordinates": [
                //                     [
                //                         [data.latitude[0], data.longitude[0]], [data.latitude[0], data.longitude[1]], [data.latitude[1], data.longitude[0]],
                //                         [data.latitude[1], data.longitude[1]], [data.latitude[0], data.longitude[0]]
                //                     ]
                //                 ]
                //             }
                //         })
                //     }
                // })
                // setGeoData({"type": "FeatureCollection", "features": geodata})
                }  
            } 
    }
    // useEffect(() => {
    //     if(testEnv === "false") {
    //         return getBagfiles()
    //     }
    // },[])
    
    
    // useEffect(() => {
    //     if(!props.ws){
    //       return
    //     }
    //     props.ws.onmessage = (msg) => {
    //       const data = JSON5.parse(msg.data)
    //     //   console.log(data)
    //       if(data.type === "dronefileAdded" || data.type === "dronefileDeleted"){     
    //         getBagfiles()
    //       }
    //       else if(data.type === "dronefileUpdated"){       
    //         getBagfiles()
    //       }
    //     }
    //   }, [props.ws])

    const [forestData, setforestData] = useState(testEnv === "false" ? null : testForestData)

    const [avgData, setAvgData] = useState(null)

    const bagfileSelected = async () => {

        var formData = new FormData();
        var token = sessionStorage.getItem("token")
        // console.log(props.selectedBagfile._id)
        formData.append("token", token);
        formData.append("id", props.selectedBagfile._id);
        // console.log(props.selectedBagfile)
        
        // console.log(typeof(props.selectedBagfile._id))
        const dbhFilter = props.filterCustomerData && props.filterCustomerData === '000007' ? 0.9 : 0.8
        var config = {
            method: "post",
            url: "api/forestdata",
            data: formData,
          };
          axios(config)
          .then(response => 
           {    
                // console.log(response.data)
                const fetchedData = response.data

                if(fetchedData.forestdata && fetchedData.forestdata.treeData){
                    const area = fetchedData.forestdata.area
                    
                    var totalData = {
                        noDBH: 0,  
                        noVol: 0,  
                        noHeight: 0,
                        possibleTrees: 0,  
                        sumDBH: 0, 
                        sumVol: 0, 
                        sumHeight: 0, 
                        area: 0, 
                        basalArea: 0,
                        DBH:[],
                        volume:[],
                        height:[],
                        discardedTrees:0,
                        Full: []

                    }
                    
                    // console.log(fetchedData.forestdata)
                    var tmp = reduceTreedata(fetchedData.forestdata.treeData.features, area)

                    // console.log(tmp)
                    totalData.noDBH = tmp.DBH.length - 1
                    totalData.noHeight = tmp.height.length - 1
                    totalData.noVol = tmp.volume.length - 1
                    totalData.possibleTrees = tmp.possibleTrees.length
                    totalData.sumDBH = tmp.sumDBH
                    totalData.sumVol = tmp.sumVol
                    totalData.sumHeight = tmp.sumHeight
                    totalData.area = area ? area : 0
                    totalData.basalArea = tmp.basalArea
                    totalData.DBH = tmp.DBH
                    totalData.volume = tmp.volume
                    totalData.height = tmp.height
                    totalData.Full = tmp.Full
                    
                    totalData.discardedTrees = tmp.discardedTrees
                    // console.log(totalData)
                    setforestData(totalData)
                }
                
                
                // console.log(area)
                else if(fetchedData.TreeData) {
                    // console.log(fetchedData.TreeData)
                    const area = props.selectedBagfile.area ? Number(props.selectedBagfile.area).toFixed(3) : 0.000
                    setforestData(fetchedData.TreeData.reduce(
                        (acc, { DBH, volume, height, ID, x, y, lon, lat, elev, heightRaw, type, method }) => ({                       
                            DBH: 0 < DBH && DBH < dbhFilter ? [...acc.DBH, ["DBH", Math.round(Number(DBH.toFixed(3)*100))*0.01]] : [...acc.DBH],
                            volume: 0 < volume && volume < 5 && 0 < DBH && DBH < dbhFilter ? [...acc.volume,["Volume", Math.round(Number(volume.toFixed(3))*100)*0.01]] : [...acc.volume],
                            height: 0 < height && height< 30 && 0 < DBH && DBH < dbhFilter ? [...acc.height, ["Height", Math.round(Number(height.toFixed(3))*100)*0.01]] : [...acc.height],
                            sumDBH: 0 < DBH && DBH < dbhFilter ? acc.sumDBH + DBH : acc.sumDBH + 0,
                            basalArea: 0 < DBH && DBH < dbhFilter ? acc.basalArea + ((Number(DBH)/2)*(Number(DBH)/2))*Math.PI : acc.basalArea + 0,
                            sumVol:  0 < volume && volume < 5 && 0 < DBH && DBH < dbhFilter ? acc.sumVol + volume : acc.sumVol + 0,
                            sumHeight: 0 < height && height< 30 && 0 < DBH && DBH < dbhFilter ? acc.sumHeight + height : acc.sumHeight + 0,
                            discardedTrees: DBH >= dbhFilter ? acc.discardedTrees + 1 : acc.discardedTrees + 0,
                            possibleTrees: DBH > 0 || volume !== -1 ? acc.possibleTrees + 1 : acc.possibleTrees + 0,
                            Full: [...acc.Full, {
                                "ID": ID,
                                "x": x, 
                                "y": y, 
                                "DBH": Number(DBH.toFixed(3)), 
                                "volume": Number(volume.toFixed(3)), 
                                "height": Number(height.toFixed(3)), 
                                "lon": lon ? lon : 0.00,
                                "lat": lat ? lat : 0.00,
                                "elev": elev ? elev : 0,
                                "heightRaw": heightRaw ? heightRaw : 0, //# Estimated as the difference between smallest and largest height
                                "type": type ? type : "",
                                "method": method ? method : ""
                            } ]
                        }),
                        { Full: [], possibleTrees: 0, DBH: [["DBH", "0"]], volume: [["Volume", "0"]], height: [["Height", "0"]], sumDBH: 0, basalArea: 0, sumVol: 0, sumHeight: 0, area: area, discardedTrees:0 }
                    ))
                } else {
                    setforestData(null)
                }
          })
          .catch(error => {
            setforestData(null)
            console.log("error", error)

          })
    }
    const [surveyAreaData, setSurveyAreaData] = useState([])
    const [totStandData, setTotStandData] = useState()
    const standSelected = async () => {

       
        var token = sessionStorage.getItem("token")
        // console.log(props.selectedBagfile._id)
        const data = {
            "token": token,
            "AID": props.ctx.selectedShapeData.current.AID,
            // "filter": props.ctx.filterBagData.current
        }
 
        // console.log(props.ctx.selectedShapeData.current)
        
        const dbhFilter = props.filterCustomerData && props.filterCustomerData === '000007' ? 0.9 : 0.7
        var config = {
            method: "post",
            url: "api/get_stand_treedata",
            data: data,
          };
          axios(config)
          .then(response => 
           {    
                // console.log(response.data)
                const fetchedData = response.data.standtreedata                

                // console.log(area)
                if(fetchedData) {
                    const area = fetchedData.area
                    const alias = fetchedData.alias
                    var totalData = {
                        noDBH: 0,  
                        noVol: 0,  
                        noHeight: 0,
                        possibleTrees: 0,  
                        sumDBH: 0, 
                        sumVol: 0, 
                        sumHeight: 0, 
                        area: 0, 
                        basalArea: 0,
                        DBH:[],
                        volume:[],
                        height:[],
                        discardedTrees:0,
                        full: []

                    }
                    
                
                    var tmp = reduceTreedata(fetchedData.treeData.features, area)
                    //console.log(data.area)
                    totalData.noDBH = tmp.DBH.length - 1
                    totalData.noHeight = tmp.height.length - 1
                    totalData.noVol = tmp.volume.length - 1
                    totalData.possibleTrees = tmp.possibleTrees.length
                    totalData.sumDBH = tmp.sumDBH
                    totalData.sumVol = tmp.sumVol
                    totalData.sumHeight = tmp.sumHeight
                    totalData.area = area
                    totalData.basalArea = tmp.basalArea
                    totalData.DBH = tmp.DBH
                    totalData.volume = tmp.volume
                    totalData.height = tmp.height
                    totalData.full = tmp.Full
                    
                    totalData.discardedTrees = tmp.discardedTrees
                    // let i = 0
                    // standDataTemp.forEach(sd => {
                    //     if(i === 0){
                    //         totalData.full = sd.Full
                    //         totalData.DBH = sd.DBH
                    //         totalData.volume = sd.volume
                    //         totalData.height = sd.height
                    //         i += 1
                    //     } else {
                    //         totalData.full = totalData.full.concat(sd.Full)
                    //         sd.DBH.shift()
                    //         sd.volume.shift()
                    //         sd.height.shift()
                    //         // console.log(sd.DBH)
                    //         totalData.DBH = totalData.DBH.concat(sd.DBH)
                    //         totalData.volume = totalData.volume.concat(sd.volume)
                    //         totalData.height = totalData.height.concat(sd.height)
                    //         i += 1
                    //     }
                    // })
                    // console.log(totalData)
                    props.ctx.setForestdata({possibleTrees: totalData.possibleTrees, area: totalData.area, basalArea: totalData.basalArea})
                    setTotStandData(totalData)
                } else {
                    props.ctx.setForestdata(null)
                    setTotStandData(null)

                }
          })
          .catch(error => {
            props.ctx.setForestdata(null)
            setTotStandData(null)
            console.log("error", error)
          })
    }
    
    useEffect(() => {
        if(!props.ctx.showShapeData?.current){
            if(forestData){
            // console.log(forestData) 
            var possibleTrees = forestData.possibleTrees
            var lengthDBH = forestData.DBH.length - 1 
            var lengthVol = forestData.volume.length - 1
            var lengthHeight = forestData.height.length - 1
            // var basalArea = ((Number(forestData.sumDBH.toFixed(3)/Number(lengthDBH))/2)*(Number(forestData.sumDBH.toFixed(3)/Number(possibleTrees))/2))*3.1415
            setAvgData({ 
                avgDBH: Number(forestData.sumDBH.toFixed(3)/lengthDBH).toFixed(3), 
                avgVol: Number(forestData.sumVol.toFixed(3)/lengthVol).toFixed(3), 
                avgHeight: Number(forestData.sumHeight.toFixed(3)/lengthHeight).toFixed(3), 
                totTrees: possibleTrees,
                basalArea: forestData.basalArea,
                area: props.selectedBagfile.area ? Number(props.selectedBagfile.area).toFixed(3) : 0.000,
            })
        } else {
            setAvgData(null)
        }
        }
    }, [forestData])

    useEffect(() => {
        if(props.selectedBagfile._id !== "" && testEnv === "false"){
        //    onSelectBagfile(props.selectedBagfile)
           bagfileSelected()
        //    getBagfiles()
           return
        } 
    }, [props.selectedBagfile])

    useEffect(() => {
            setforestData()
        if(props.ctx.selectedShapeData.current.AID !== "" && testEnv === "false"){
            setSurveyAreaData([])
            setTotStandData()
            standSelected()
            // getBagfiles()
            return
        } 
    }, [props.ctx.selectedShapeData.current, props.ctx?.showShapeData?.current])

    // useEffect(() => {
    //     if(testEnv === 'false' ){
    //       getBagfiles()
    //       return
    //     }
    //   }, [props.filterCustomerData, props.ctx?.showShapeData?.current])

    var setListAndHistInColumn = isRowBased ? "column" : "" 
    var setListSumColumn = isAllRowBased ? "column" : "" 
    var setMaxWidthListSum = isRowBased ? "100%" : "50%"
    var setMaxWidthAllHist = isAllRowBased ? "100%" : isRowBased?  "100%" : "50%"
    var setNarrowMaxWidthListHist = isAllRowBased ? "100%" : "50%"
    var setMaxHeightListSum = isRowBased ? "50%" : ""
    var sumWidth = isAllRowBased ? "90%" : "300px"
    var setTopDivHeight = isRowBased && !forestData ? "100%" : isRowBased ? "fit-content" : "100%"
    var heightItemList = isAllRowBased ? "350px" : "100%"
    var alignListSum = isAllRowBased ? "center" : ""
    const [chartWrapper, setChartWrapper] = useState({'DbhHist': null, 'VolumeHist': null, 'HeightHist': null })
    const setNewChartWrapper =(chartType, chart ) => {
        chartWrapper[chartType] = chart 
        setChartWrapper(chartWrapper)
    }

    const histogramDBH = ((forestData && avgData && props.selectedBagfile && !props.ctx.showShapeData?.current) || (props.ctx.showShapeData?.current && totStandData && totStandData.possibleTrees > 0)) ? 
        <ForestDetailsHistogram id="DbhHist" setState={setNewChartWrapper} data={props.ctx.showShapeData?.current ? totStandData.DBH : forestData.DBH} name={"DBH"} title={"DBH distribution in " + props.selectedBagfile.name} xLabel="DBH (meter), bin width is 0.02m" 
            histogramSettings={props.selectedBagfile.customerId && customerDbhSettings[props.selectedBagfile.customerId] ? customerDbhSettings[props.selectedBagfile.customerId] : customerDbhSettings['101010']}/> : null
    const histogramVolume = (forestData && avgData && props.selectedBagfile && !props.ctx.showShapeData?.current) || (props.ctx.showShapeData?.current && totStandData && totStandData.possibleTrees > 0) ? 
        <ForestDetailsHistogram id="VolumeHist" setState={setNewChartWrapper} data={props.ctx.showShapeData?.current ? totStandData.volume : forestData.volume} name={"Volume"} title={"Volume distribution in " + props.selectedBagfile.name} xLabel="Volume (meter³) bin width is 0.05m³" histogramSettings={props.selectedBagfile.customerId && customerVolumeSettings[props.selectedBagfile.customerId] ? customerVolumeSettings[props.selectedBagfile.customerId] : customerVolumeSettings['101010']}/> : null
    const histogramHeight = (forestData && avgData && props.selectedBagfile && !props.ctx.showShapeData?.current) || (props.ctx.showShapeData?.current && totStandData && totStandData.possibleTrees > 0) ? 
        <ForestDetailsHistogram id="HeightHist" setState={setNewChartWrapper} data={props.ctx.showShapeData?.current ? totStandData.height : forestData.height} name={"Height"} title={"Height distribution in " + props.selectedBagfile.name} xLabel="Height (meter) bin width is 0.5m" histogramSettings={props.selectedBagfile.customerId && customerHeightSettings[props.selectedBagfile.customerId] ? customerHeightSettings[props.selectedBagfile.customerId] : customerHeightSettings['101010']}/> : null

    const summary = ( 
        <ForestDetailsSummary 
            toggleEditTable={toggleEditTable}
            editTable={editTableRef.current}
            chartWrappers={chartWrapper}
            viewTable={viewTable}
            setViewTable={toggleViewTable}
            style={{minWidth: sumWidth}} 
            name={testEnv === "true" ? "This is a very long name, wow, such long, can it be this lonoooooooog" : props.selectedBagfile.name} 
            forestData={forestData}
            avgData={avgData}
            // totalTrees={props.ctx.showShapeData?.current ? [[]] : forestData.possibleTrees} 
            // basalArea={props.ctx.showShapeData?.current ? 0.00 : avgData.basalArea} 
            // area={props.ctx.showShapeData?.current ? 0.00 : avgData.area}
            // avgDBH={props.ctx.showShapeData?.current ? 0 : avgData.avgDBH} 
            // avgVol={props.ctx.showShapeData?.current ? 0 : avgData.avgVol} 
            // avgHeight={props.ctx.showShapeData?.current ? 0 : avgData.avgHeight} 
            // discardedTrees={props.ctx.showShapeData?.current ? 0 : forestData.discardedTrees}
            // full={props.ctx.showShapeData?.current ? [[]] : forestData.Full}
            ctx={props.ctx}
            totStandData={totStandData}
            downloadGeojson={openDownloadGeojsonDialog}
            downloadCSV={openDownloadCSVDialog}

        />) 
        // (forestData && avgData && forestData.DBH.length > 1 && !props.ctx.showShapeData?.current) || (props.ctx.showShapeData?.current && totStandData && totStandData.possibleTrees > 0) ? 
        // : forestData && avgData && forestData.DBH.length < 2 ? 
        // (<div style={{color: "#a3de00", width: "100%", textAlign: "center", padding: "1em"}} >
        //     <h5>Only one tree found with DBH, look at table data.</h5>
        // </div>) :
        // (<div style={{color: "#a3de00", width: "100%", textAlign: "center", padding: "1em"}} >
        //     <h5>Select a file to see forest data</h5>
        //     <p>Number of forest files: {bagData.length}</p>
        // </div>)
        
    const histogram = forestData || totStandData ? (
        <div style={{flexDirection: "column", width: "100%", display: "flex", stroke: "#adaaaa", strokeWidth: "0.5", alignItems: "center"}}>
            {histogramDBH}
            {histogramVolume} 
            {histogramHeight}
        </div>) : null
        

    const map =  isAllRowBased ? null : 
                (<div style={{height:"100%", bottom:"1em 1em 1.5em 1em", margin: "1em", border: "3px outset #0f5132"}}>
                    <MapView mapStyle={{width: "100%", height:"100%"}} 
                        mapref={props.forestDetailMapRef}
                        updateVisibleItems={props.updateVisibleItems}
                        geoData={props.bagData}
                        hasControlers={false} 
                        selectedBagfile={props.selectedBagfile} 
                        setSelectedBagfile={props.setSelectedBagfile}
                        width="100%" height="100%" 
                        setPopupInfo={() => null}
                        showCluster={false}
                        pins={null}
                        ctx={props.ctx}/>
                </div> )  

    const [editMode, setEditMode] = useState(false)
    const updateEditMode = () => {
        setEditMode(!editMode)
    }
    const editModeRef = useRef()
    editModeRef.current = editMode
    const tableView = (forestData && viewTable && !props.ctx.showShapeData?.current) ? 
        (<ForestDetailsIndividualTrees 
            editMode={editModeRef.current} 
            updateEditMode={updateEditMode}
            // selectedItemsToRemove={selectedItemsToRemoveRef.current} 
            // setSelectedItemsToRemove={updateSelectedItemsToRemove} 
            removeSelectedTrees={removeSelectedTrees}
            trees={forestData.Full} selectedBagfile={props.selectedBagfile}/>) : 
        null
    const toggleSortOrder = () => {
        const newSortOrder = props.sortOrder === "asc" ? "desc" : "asc";
        props.setSortOrder(newSortOrder);
        if((!props.fromDate || !props.toDate) && props.filteredItems && props.filteredItems.length<1){
            console.log("not filtered")
            
            createDronefileList(props.sortItems(props.bagData, newSortOrder));
        } else {
            createDronefileList(props.sortItems(props.filteredItems, newSortOrder));
        }
    };
    const filterButton = (
        <Dropdown style={{marginLeft: "1em"}}>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            </Dropdown.Toggle>

            <Dropdown.Menu>
            {hasAccess(customer) && <Dropdown.Item  onClick={() => props.updateFilters('notReviewed')} href="#" eventKey="NotReviewed"> 
                <Form.Check 
                    type="switch"
                    id={`switch-notReviewed`}
                    label={'Not reviewed'}
                    checked={props.filters.includes('notReviewed')}
                    // onChange={() => updateFilters('notReviewed')}
                    className="w-100"
                    /></Dropdown.Item>}
                    {hasAccess(customer) && <Dropdown.Item  onClick={() => props.updateFilters('reviewed')} href="#" eventKey="reviewed"> 
                <Form.Check 
                    type="switch"
                    id={`switch-reviewed`}
                    label={'Reviewed'}
                    checked={props.filters.includes('reviewed')}
                    // onChange={() => updateFilters('notReviewed')}
                    className="w-100"
                    /></Dropdown.Item>}
                    {hasAccess(customer) && <Dropdown.Item  onClick={() => props.updateFilters('notInStand')} href="#" eventKey="notInStand"> 
                <Form.Check 
                    type="switch"
                    id={`switch-notInStand`}
                    label={'Not in stand'}
                    checked={props.filters.includes('notInStand')}
                    // onChange={() => updateFilters('notReviewed')}
                    className="w-100"
                    /></Dropdown.Item>}
                    {hasAccess(customer) && <Dropdown.Item  onClick={() => props.updateFilters('inStand')} href="#" eventKey="inStand"> 
                <Form.Check 
                    type="switch"
                    id={`switch-inStand`}
                    label={'In stand'}
                    checked={props.filters.includes('inStand')}
                    // onChange={() => updateFilters('notReviewed')}
                    className="w-100"
                    /></Dropdown.Item>}
                
                {/* {hasAccess(customer) && <Dropdown.Item variant={props.filters.includes('reviewed') ? "info" : ""} onClick={() => props.updateFilters('reviewed')} href="#" eventKey="Reviewed">{props.filters.includes('reviewed') ? `Reviewed ✅` : 'Reviewed'}</Dropdown.Item>}
                {hasAccess(customer) && <Dropdown.Item variant={props.filters.includes('notInStand') ? "info" : ""} onClick={() => props.updateFilters('notInStand')} href="#" eventKey="NotInStand">{props.filters.includes('notInStand') ? `Not in stand ✅` : 'Not in stand'}</Dropdown.Item>}
                {hasAccess(customer) && <Dropdown.Item variant={props.filters.includes('inStand') ? "info" : ""} onClick={() =>props.updateFilters('inStand')} href="#" eventKey="OnlyInStand">{props.filters.includes('inStand') ? `In stand ✅` : 'In stand'}</Dropdown.Item>} */}
                {hasAccess(customer) && <Dropdown.Item  onClick={toggleSortOrder} href="#" eventKey="sortBydate"> 
                    Sort by date{" "}
                    {props.sortOrder === "asc" ? (
                        <span style={{ marginLeft: "5px" }}>↑</span> // Up arrow for ascending
                        ) : (
                            <span style={{ marginLeft: "5px" }}>↓</span> // Down arrow for descending
                        )}</Dropdown.Item>}
                <Dropdown.Item onClick={() => props.updateFilters("clear")} href="#" eventKey="ClearFilters">Clear Filters</Dropdown.Item>
    
            </Dropdown.Menu>
        </Dropdown> )
    return (
            <div style={{ display: "flex", fontSize: "smaller", flexDirection: setListAndHistInColumn, justifyContent: "space-between", width:"100%", height: setTopDivHeight, backgroundColor: "#5c5c5c"}}> 
                {exportModal}
                <div style={{display: "flex", flexDirection: setListSumColumn, width: setMaxWidthListSum, height: setMaxHeightListSum, alignItems: alignListSum} }> 
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", width: setNarrowMaxWidthListHist}} >
                        <Card 
                        bg={"dark"}
                        key={"ForestDetailsItems"}
                        text={"white"}
                        style={{minWidth: "200px",fontSize: "x-small", width: "95%", height: heightItemList, margin: "1em", border: "3px outset #0f5132"}}
                        >
                            <Card.Header style={{fontSize: "1.3em", fontWeight: "bold", marginBottom: "1em" }}>
                                <div style={{display: "flex"}}>
                                <Button style={{border: "2px outset #00d098", margin:"0.5em"}} size='sm' variant="outline-secondary"
                                    onClick={props.ctx.toggleShowShapeData}> 
                                    {props.ctx.showShapeData?.current ?  "Show individual flights" : "Show stand areas"}
                                </Button>   
                                {hasAccess(customer) && 
                                <Button style={{border: "2px outset #00d098", margin:"0.5em"}} size='sm' variant="outline-secondary"
                                    onClick={toggleViewFlightReport}> 
                                    {viewFlightReport ?  "Show Hist" : "Show flight report"}
                                </Button>  }
                                </div>
                        <div style={{display: 'flex'}}>
                                
                               
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <div style={{display:'flex', margin:"0.5em", alignItems: "center"}}>
                                        <label>
                                        From:{" "}
                                        <input
                                            type="date"
                                            value={props.fromDate}
                                            onChange={(e) => props.setFromDate(e.target.value)}
                                        />
                                        </label>
                                        {filterButton} 
                                        </div>
                                        <div style={{display:'flex', margin:"0.5em", alignItems: "center"}}>
                                        <label>
                                        To:{" "}
                                        <input
                                            style={{ marginLeft: "17px" }}
                                            type="date"
                                            value={props.toDate}
                                            onChange={(e) => props.setToDate(e.target.value)}
                                        />
                                        </label>
                                        <Button
                                        onClick={props.filterItems}
                                        style={{border: "2px outset #00d098", marginLeft:"1em"}} size='sm' variant="outline-secondary"
                                        >
                                        Filter dates
                                        </Button> 
                                        </div>
                                        
                                </div>  
                                  
                                                
                        </div>
                            </Card.Header>
                            <Element name="forestDetailsItems" className="element" id="containerElement" style={{
                            position: "relative",
                            height: "100%",
                            width: "100%",
                            overflowY: "auto",
                            overflowX: "Hidden",
                            marginBottom: "1.5em",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                            }}>
                                <ListGroup style={{width: "95%", padding: "1em", position: "absolute"}}>
                                        {props.ctx.showShapeData?.current ? shapeData : bagData ? bagData : "loading..."}
                                </ListGroup>
                            </Element>
                        </Card>
                        
                    </div>
                    <div style={{minWidth: sumWidth, width:"60%", display:"flex", flexDirection: "column", }}>
                        {summary}  
                        {isRowBased ? null : map} 
                    </div>
                </div>
                <div style={{width: setMaxWidthAllHist, height: setMaxHeightListSum, display: "flex", justifyContent: "space-between"}}>
                    {isAllRowBased ? null: isRowBased ?
                    <div style={{width: "45%", minHeight: "100%", paddingTop: "3rem"}}>
                        {map} 
                    </div> : null } 
                    {viewFlightReport ? <FlightReport ctx={props.ctx} selectedBagfile={props.selectedBagfile}/> : viewTable ? tableView : histogram} 
                    
                </div>
                <DownloadDialogPopup ctx={props.ctx} itemToDownload={itemToDownload} setItemToDownload={setItemToDownload} downloadDialogShow={downloadDialogShow} setDownloadDialogShow={setDownloadDialogShow}/>
                <DownloadGeoDialogPopup ctx={props.ctx} itemToDownload={itemToDownload} setItemToDownload={setItemToDownload} downloadGeoDialogShow={downloadGeoDialogShow} setDownloadGeoDialogShow={setDownloadGeoDialogShow}/>
                <DownloadFlightPathDialogPopup itemToDownload={itemToDownload} downloadFlightPathDialogShow={downloadFlightPathDialogShow} setDownloadFlightPathDialogShow={setDownloadFlightPathDialogShow}/>
                <DownloadGeojsonPopup ctx={props.ctx} itemToDownload={itemToDownload} downloadGeojsonDialogShow={downloadGeojsonDialogShow} setDownloadGeojsonDialogShow={setDownloadGeojsonDialogShow}/>
                <DownloadCSVPopup ctx={props.ctx} itemToDownload={itemToDownload} downloadCSVDialogShow={downloadCSVDialogShow} setDownloadCSVDialogShow={setDownloadCSVDialogShow}/>
                {/* <EditDialogPopup itemsToRemove={selectedItemsToRemoveRef.current} handleEdit={handleEdit} editDialogShow={editDialogShow} setEditDialogShow={setEditDialogShow}/> */}
                <DeleteDialogPopup itemToDelete={itemToDelete} handleDelete={handleDelete} deleteDialogShow={deleteDialogShow} setDeleteDialogShow={setDeleteDialogShow}/>
            </div>
    )
}

ForestDetails.propTypes = {
    selectedBagfile: PropTypes.object.isRequired,
    setSelectedBagfile: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    ws: PropTypes.object.isRequired,
    filterCustomerData: PropTypes.string.isRequired,
    setFilterCustomerData: PropTypes.func.isRequired,
    ctx: PropTypes.object.isRequired,
    forestDetailMapRef: PropTypes.object.isRequired,
    bagData: PropTypes.array,
    filteredItems: PropTypes.array.isRequired,
    sortOrder: PropTypes.string.isRequired,
    setSortOrder: PropTypes.string.isRequired,
    // clearFilter: PropTypes.func.isRequired,
    // toggleSortOrder: PropTypes.func.isRequired,
    filterItems: PropTypes.func.isRequired,
    setFromDate: PropTypes.func.isRequired,
    sortItems: PropTypes.func.isRequired,
    fromDate: PropTypes.string.isRequired,
    setToDate: PropTypes.func.isRequired,
    toDate: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    updateFilters: PropTypes.func.isRequired,
    updateVisibleItems: PropTypes.func.isRequired,
}
