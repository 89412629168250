import React, { useEffect, useState, useRef }  from 'react';
import Map, {
    Layer,
    Source,
    NavigationControl,
    FullscreenControl,
    ScaleControl,
    GeolocateControl,
    Popup
   } from 'react-map-gl';
import MAP_STYLE from '../../data/style.json'
import { styles } from './styles'
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from 'mapbox-gl';
// Be sure to include styles at some point, probably during your bootstraping
import PropTypes from 'prop-types'
import useCoord from '../App/useCoord';
import "./map.css"

// { type: "Feature", "properties": { "id": "ak16994521", "mag": 2.3, "time": 1507425650893, "felt": null, "tsunami": 0 }, "geometry": { "type": "Point", "coordinates": [ -151.5129, 63.1016, 0.0 ] } },

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
const MAPBOX_TOKEN = process.env.REACT_APP_NOT_SECRET_CODE3

function uuidToInt(uuid) {
  // Validate the UUID format
  if (!/^[a-f0-9]{24}$/i.test(uuid)) {
      throw new Error("Invalid UUID format.");
  }

  let hash = 0;
  for (let i = 0; i < uuid.length; i++) {
      let char = uuid.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;   // Bitwise left shift and addition
      hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
export default function MapView(props) {
  const [popupInfo, setPopupInfo] = useState(null)
  const [popupInfoCoord, setPopupInfoCoord] = useState({longitude: null, latitude: null})
  const {coord} = useCoord()
  const defaultCoord = useRef(coord)

  const onMapClick = event => {
    // console.log(event.features)
    
    const feature = event.features[0];
    // console.log(feature)
    const layer = feature && feature.layer;
    if(feature && layer.id === 'clusters' && props.showCluster){
      const clusterId = feature.properties.cluster_id;

      const mapboxSource = props.mapref.current.getSource('markers');

      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          return;
        }

        props.mapref.current.easeTo({
          center: feature.geometry.coordinates,
          zoom,
          duration: 700,
        });
      });
    }
    else if (feature && layer.id === 'unclustered-point'){
      // console.log(feature.properties)
      const properties = feature.properties
      if(props.selectedBagfile._id !== properties._id){
        if(!props.ctx.showShapeData?.current){
          props.setSelectedBagfile(properties)
        }
      }
      setPopupInfoCoord(properties)
    } else if (feature && layer.id === 'standPolygon'){
     
      const properties = feature.properties
      const item = JSON.parse(properties.item)
      // console.log(item)
      // console.log(props.ctx.selectedShapeData.current.AID)
      
      if(item && item.AID && props.ctx.selectedShapeData.current.AID !== item.AID){
        if(props.ctx.showShapeData?.current){
          props.ctx.selectShapeData(item)
        }
      }
      // console.log(properties.lon)
      // console.log(properties.lat)
      setPopupInfoCoord({longitude: Number(properties.lon), latitude: Number(properties.lat), alias: item.alias, isMerged: item.mergedState})
      // const properties = feature.properties
      // if(props.selectedBagfile._id !== properties._id){
      //   props.setSelectedBagfile(properties)
      // }
      // setPopupInfoCoord(properties)
    }
  };

  useEffect(()=> {
    
    if(popupInfoCoord.latitude){
      // console.log(props.ctx.forestdata)
      const info =  
      (<Popup
        anchor="top"
        longitude={popupInfoCoord.longitude}
        latitude={popupInfoCoord.latitude}
        onClose={() => {
          setPopupInfo(null)
          setPopupInfoCoord({longitude: null, latitude: null})
        }}
      >
        <div>
          {popupInfoCoord.alias ? popupInfoCoord.alias : popupInfoCoord.name}
          {popupInfoCoord.area && <p>{'area (hectare): '+Number(popupInfoCoord.area).toFixed(3)}</p>}
          {!props.ctx.showShapeData?.current ? null : (props.ctx.showShapeData?.current && props.ctx.forestdata.area === 0 && (popupInfoCoord.isMerged && popupInfoCoord.isMerged !== 'notMerged')) ?  <p>Loading data...</p> :(props.ctx.forestdata.area !== 0 &&  ((popupInfoCoord.isMerged && popupInfoCoord.isMerged !== 'notMerged')) || popupInfoCoord.customerId) ? null : <p>No merged data</p>}
          {props.ctx.forestdata.area !== 0 && <p>{'Area scanned (hectare): '+Number(props.ctx.forestdata.area).toFixed(3)}</p>}
          {props.ctx.forestdata.basalArea !== 0 && <p>{'Basal area (m²/ha): '+Number(props.ctx.forestdata.basalArea).toFixed(3)}</p>}
          {props.ctx.forestdata.possibleTrees !== 0 && <p>{'Number of trees: '+Number(props.ctx.forestdata.possibleTrees)}</p>}
          
        </div>
      </Popup>)
      setPopupInfo(info)
      // const features = props.mapref.current.queryRenderedFeatures({layers: ['unclustered-point']})
      // const features = props.mapref.current.querySourceFeatures('markers');
      //console.log(features)
    }
  },[popupInfoCoord, props.ctx.forestdata])

  const transitionEnded = () =>{
    // console.log('transition ended')coords
    if(props.mapref.current){

      // const markerFeatures = props.mapref.current.querySourceFeatures('markers');
      // const areaFeatures = props.mapref.current.querySourceFeatures('potree-zones');
      props.updateVisibleItems()
      if(props.selectedBagfile && props.selectedBagfile._id !== '') {
        const _id = uuidToInt(props.selectedBagfile._id)
        
        props.mapref.current.setFeatureState(
          { source: 'markers', id: _id },
          { selected: true }
        )
        props.mapref.current.setFeatureState(
          { source: 'potree-zones', id: _id},
          { selected: true }
        )
        
        if(props.ctx?.prevSelectedBagfileId !== ""){
          const prev_id = uuidToInt(props.ctx.prevSelectedBagfileId)

          props.mapref.current.setFeatureState(
            { source: 'markers', id: prev_id },
            { selected: false }
          )
          props.mapref.current.setFeatureState(
            { source: 'potree-zones', id: prev_id},
            { selected: false }
          )
        }
        // markerFeatures.forEach(f => {
        //     const selected = f.properties._id === props.selectedBagfile._id
        //     if(!selected){
        //       props.mapref.current.setFeatureState(
        //         { source: 'markers', id: f.id },
        //         { selected: false }
        //       )
        //     }
            
        // })
        // areaFeatures.forEach(i => { 
        //   const selected = i.properties._id === props.selectedBagfile._id
        //   if(!selected){
        //     props.mapref.current.setFeatureState(
        //       { source: 'potree-zones', id: i.id },
        //       { selected: false }
        //     )
        //   }
         
      // })

        // let feature = features.filter(f => f.properties._id === props.selectedBagfile._id)
        // console.log('feature selected: '+feature)
        // if(feature){
        //   if(feature.length > 1){
        //     feature = feature[0]
        //   }
        //   props.mapref.current.setFeatureState(
        //     { source: 'markers', id: feature.id },
        //     { selected: true }
        //     );
        // }
      }
    }
  }

  const flightPathLayer = {
    id: 'flightPath',
    type: 'line',
    source: 'flightPathSource',
    paint: {
      "line-color": "red", // Red color
      "line-width": 1, // Line thickness
      "line-opacity": 0.8, // Opacity
    }
  }

  const dataLayer = {
    id: 'polygon',
    type: 'fill',
    source: 'potree-zones',
    paint: {
      'fill-color': [
        'case', 
        ['boolean',['feature-state', 'selected'], false],
        'rgba(0, 255, 0, 0.3)',
        'rgba(170, 167, 0, 0.3)',
      ],
      // 'fill-opacity': 0.3,
      'fill-outline-color': "rgba(61, 61, 61, 1)",
    }
  }

  const standDataLayer = {
    id: 'standPolygon',
    type: 'fill',
    source: 'standAreas',
    paint: {
      'fill-color': 'rgba(13, 207, 178, 0.3)',
      // 'fill-opacity': 0.3,
      'fill-outline-color': "rgba(61, 61, 61, 1)",
    }
  }
  const clusterLayer = {
    id: 'clusters',
    type: 'circle',
    source: 'markers',
    filter: ['has', 'point_count'],
    paint: {
      "circle-color": "#f1f075",
      "circle-radius": 30
    }
  }
  
  const clusterCountLayer = {
    id: 'cluster-count',
    type: 'symbol',
    source: 'markers',
    
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
      "text-allow-overlap" : true
    }
  };
  
  const unclusteredPointLayer = {
    id: 'unclustered-point',
    type: 'circle',
    source: 'markers',
    sourceLayer: 'points',
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-color': [
        'case', 
        ['boolean',['feature-state', 'selected'], false],
        '#c71616',
        '#0032e6'
      ],
      'circle-radius':  [
        'case', 
        ['boolean',['feature-state', 'selected'], false],
        7,
        5],
      'circle-stroke-width': [
        'case', 
        ['boolean',['feature-state', 'selected'], false],
        2,
        1],
      'circle-stroke-color': '#fff'
    }
  };
  
  const [allPolygonData, setAllPolygonData] = useState({
    type: "FeatureCollection",
    features: []
  })
  const [allPointerData, setAllPointerData] = useState({
    type: 'FeatureCollection',
    features: []
  })
  useEffect(()=> {
   
    if(props.mapref.current){
      const polySource = props.mapref.current.getSource('potree-zones');
      const standSource = props.mapref.current.getSource('standAreas');
      const pointSource = props.mapref.current.getSource('markers');

      if(!props.showStands){
        standSource.setData({
          type: 'FeatureCollection',
          features: []
        })
      }
      if(props.ctx.showShapeData?.current || props.showStands){
        if(Array.isArray(props.ctx.surveyArea?.current)){
          const updatedPointerData = []
          const updatedPolygonData = []
          props.ctx.surveyArea?.current.forEach((item) => {
            const _id = uuidToInt(item._id)
            item.geojson.features.forEach(feature => {

              feature['id'] = _id
              
              updatedPolygonData.push(feature)
              let type = feature.geometry.type
              if(String(type).toLowerCase().includes('polygon')){
                let coords = feature.geometry.coordinates
                if(String(type).toLowerCase() === 'multipolygon'){
                  coords =  coords[0][0][0]
                } else {
                  coords =  coords[0][0]
                }
                updatedPointerData.push(
                  {
                    type: 'Feature',
                    id: _id,
                    properties: item,
                    geometry: {
                      type: 'Point',
                      coordinates: coords
                    }
                })
              } else {
                updatedPointerData.push(
                  {
                    type: 'Feature',
                    id: _id,
                    properties: item,
                    geometry: {
                      type: 'Point',
                      coordinates: feature.geometry.coordinates
                    }
                })
                
              }
              
            })
              
          })
          
          props.ctx.surveyArea?.current.forEach(item => {
            item.geojson.features.forEach(feature => {
              let tmp = {...feature}
              let type = feature.geometry.type
              if(String(type).toLowerCase().includes('polygon')){
                let coords = feature.geometry.coordinates
                if(String(type).toLowerCase() === 'multipolygon'){
                  coords =  coords[0][0][0]
                } else {
                  coords =  coords[0][0]
                }
                let lon = coords[0]
                let lat = coords[1]
                tmp['properties'] = {item: item, lon: lon, lat: lat}  
              } else {
                tmp['properties'] = {item: item}  
              }
              updatedPolygonData.push(tmp)
              
            })
          })
          // console.log(updatedPointerData)
          // console.log(updatedPolygonData)
          standSource.setData({
            "type": "FeatureCollection",
            "features": updatedPolygonData
          })
        //   pointSource.setData({
        //     "type": "FeatureCollection",
        //     "features": updatedPointerData
        // })
          
        }
      } else {
        if(props.geoData){
          const updatedPointerData = []
          props.geoData.forEach((item) => {
            const _id = uuidToInt(item._id)
            if(item.longitude){
              updatedPointerData.push(
                {
                  type: 'Feature',
                  id: _id,
                  properties: item,
                  geometry: {
                    type: 'Point',
                    coordinates: item.latitude ? [item.longitude, item.latitude] : []
                  }
                }
              ) 
            }   
          })
          
          
          const updatedPolygonData = []
          
          props.geoData.forEach((item) => {
            const _id = uuidToInt(item._id)
            updatedPolygonData.push( 
              {
                type: 'Feature',
                id: _id,
                properties: {_id:item._id, area: item.area, alias: item.alias ? item.alias : item.name},
                geometry: {
                  coordinates: item.polygon ? [item.polygon] : [
                    [
                      [
                        17.674246723065664,
                        59.83920052525815
                      ],
                      [
                        17.67156362062798,
                        59.837727023894445
                      ],
                      [
                        17.67724181415744,
                        59.83681780967032
                      ],
                      [
                        17.678489768779883,
                        59.83894972112063
                      ],
                      [
                        17.676867427770787,
                        59.839702127865394
                      ],
                      [
                        17.674246723065664,
                        59.83920052525815
                      ]
                    ]
                  ],
                  type: "Polygon"
                }
              })
            })
            polySource.setData({
              "type": "FeatureCollection",
              "features": updatedPolygonData
            })
            pointSource.setData({
              "type": "FeatureCollection",
              "features": updatedPointerData
          })
        }
      }
  }
    // console.log(props.geoData)
  },[props.geoData, props.ctx?.showShapeData?.current, props.ctx.shapeData?.current, props.showStands])

  
  const controllers = props.hasControlers ? (
      <>
          <GeolocateControl style={styles.geolocateStyle} />
          <FullscreenControl style={styles.fullscreenControlStyle} />
          <NavigationControl style={styles.navStyle} />
          <ScaleControl style={styles.scaleControlStyle} /> 
      </>
      ) : null  
  const interactiveLayerIds = props.showCluster ? [clusterLayer.id, dataLayer.id, unclusteredPointLayer.id, standDataLayer.id] : [dataLayer.id, unclusteredPointLayer.id, standDataLayer.id]
  
  return(
    <div style={props.mapStyle}>
        <Map  
          initialViewState={{
            latitude: defaultCoord.current.latitude,
            longitude: defaultCoord.current.longitude,
            zoom: 6
          }}  
          style={{height: props.height, width: props.width}}      
          width={props.width}
          height={props.height}
          // onViewportChange={props.setViewport}
          mapboxAccessToken={MAPBOX_TOKEN}
          mapStyle={MAP_STYLE}
          interactiveLayerIds={interactiveLayerIds}
          onClick={onMapClick}
          ref={props.mapref}
          onMoveEnd={() => transitionEnded()}
          //interactiveLayerIds={['polygon']}
          // onClick={onClick}
        >
          {controllers}
          <Source id='standAreas' type='geojson' 
          // data={allPolygonData}
          >
            <Layer
            {...standDataLayer}
            />
          </Source>
          <Source id='potree-zones' type='geojson' generateId={false}
          // data={allPolygonData}
          >
            <Layer
            {...dataLayer}
            />
          </Source>
          
          <Source 
            id='markers' 
            type='geojson' 
            // data={allPointerData} 
            cluster={props.showCluster}
            clusterMaxZoom={14}
            clusterRadius={25}
            generateId={false}
            // clusterMaxZoom={14}
            // clusterRadius={50}
          >
            {props.showCluster && <Layer {...clusterLayer}/>}
            {props.showCluster && <Layer {...clusterCountLayer} />}
            <Layer {...unclusteredPointLayer} />
          </Source>
          <Source id="flightPath-source" type="geojson">
          <Layer
            id="flightPath-layer"
            type="line"
            paint={{
              "line-color": "red", // Red color
              "line-width": 2, // Line thickness
              "line-opacity": 0.8, // Opacity
            }}
          />
        </Source>
          {popupInfo}
          {props.pins}
          {/* {props.dropZone} */}
        </Map>
    </div>
  );
}

MapView.propTypes = {
    selectedBagfile: PropTypes.object.isRequired,
    setSelectedBagfile: PropTypes.func.isRequired,
    mapref: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    hasControlers: PropTypes.bool.isRequired,
    mapStyle: PropTypes.object.isRequired,
    // dropZone: PropTypes.object,
    popup: PropTypes.object,
    popupInfo: PropTypes.object,
    setPopupInfo: PropTypes.func,
    geoData: PropTypes.array,
    pins: PropTypes.array,
    showCluster: PropTypes.bool.isRequired,
    ctx: PropTypes.object.isRequired,
    showStands: PropTypes.bool.isRequired,
    updateVisibleItems: PropTypes.func.isRequired,
}